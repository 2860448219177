import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Parallax } from 'react-parallax';
import Slider from 'react-slick';
import { Gallery } from 'gatsby-theme-gallery';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SEO from 'components/seo';

import './index.css';

library.add(fab);

const settings = {
  className: 'center',
  centerPadding: '60px',
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "snow kleffy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oxygen: file(relativePath: { eq: "oxygen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oxygen2: file(relativePath: { eq: "oxygen.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      af1: file(relativePath: { eq: "af1.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rip: file(relativePath: { eq: "rip.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      coldfeet: file(relativePath: { eq: "coldfeet.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      telescope: file(relativePath: { eq: "telescope.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      maxpayne: file(relativePath: { eq: "maxpayne.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      splashtized2: file(relativePath: { eq: "splashtized2.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO />
      <div className="navbar">
        <Link to="/">
          <img src="logo.png" alt="Kleffy Le Wave" width="300" />
        </Link>
        <div className="nav">
          <a
            href="https://instagram.com/splashgod"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
          </a>
          <a
            href="https://twitter.com/kingkleffy"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
          </a>
          <a
            href="https://facebook.com/kleffylewave"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
          </a>
          <a
            href="https://youtube.com/c/kleffylewave"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'youtube']} size="2x" />
          </a>
          <a
            href="https://open.spotify.com/artist/6YTjF0sBNycjyxW3iuQ7SL"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'spotify']} size="2x" />
          </a>
          <a
            href="https://itunes.apple.com/ca/artist/kleffy-le-wave/1447049670"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'apple']} size="2x" />
          </a>
          <a
            href="https://soundcloud.com/thesplashgod"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={['fab', 'soundcloud']} size="2x" />
          </a>
        </div>
      </div>
      <div className="content">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage="galaxy.jpg"
          bgImageAlt="waves"
          strength={500}
        >
          <div className="header">
            <div>
              <h2>THE NEW SINGLE</h2>
              <h1>OXYGEN</h1>
              <a
                href="https://ffm.to/oxygen-single"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>OUT NOW</button>
              </a>
            </div>
            <a
              href="https://ffm.to/oxygen-single"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Img
                fadeIn={false}
                fluid={data.oxygen.childImageSharp.fluid}
                alt="OXYGEN"
                className="image"
              />
            </a>
          </div>
        </Parallax>

        <div className="banner">
          <div className="about">
            <Img
              className="profile"
              fadeIn={false}
              fluid={data.profile.childImageSharp.fluid}
              alt="Kleffy Le Wave"
            />
            <div className="bio">
              Michael Forson, better known by his stage name Kleffy Le Wave, is
              a Canadian recording artist from Ottawa, Ontario, Canada. Kleffy
              began releasing music in the early 2010s as Mike Kleff, or Koolaid
              Kleff. His first official release as Kleffy Le Wave was the single
              “PUPPETS” which dropped on December 31, 2014, onto his SoundCloud.
              Kleffy's debut EP came with <i>SPLASHTiZED</i> released December
              16, 2018. Kleffy quickly followed up with the sequel EP{' '}
              <a
                href="https://smarturl.it/e57al6"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i>SPLASHTiZED 2</i>
              </a>{' '}
              on December 27, 2018 to finish out the year.
              <br /> Starting off 2019, Kleffy dropped the single{' '}
              <a
                href="https://smarturl.it/7iuyzh"
                rel="noopener noreferrer"
                target="_blank"
              >
                "Max Payne"
              </a>{' '}
              on January 8, 2019. Kleffy continued to drop singles throughout
              2019 with{' '}
              <a
                href="https://smarturl.it/telescope-single"
                rel="noopener noreferrer"
                target="_blank"
              >
                "Telescope"
              </a>{' '}
              on April 3, 2019,{' '}
              <a
                href="https://smarturl.it/coldfeet"
                rel="noopener noreferrer"
                target="_blank"
              >
                "Cold Feet"
              </a>{' '}
              on May 1, 2019, and his latest single to date{' '}
              <a
                href="https://smarturl.it/restinprada"
                rel="noopener noreferrer"
                target="_blank"
              >
                "Rest In Prada"
              </a>{' '}
              on September 6, 2019.
              <br /> In 2020, Kleffy has teased plenty of snippets on his{' '}
              <a
                href="https://instagram.com/splashgod"
                rel="noopener noreferrer"
                target="_blank"
              >
                Instagram
              </a>{' '}
              with his first single of 2020{' '}
              <a
                href="https://ffm.to/kleffylewave"
                rel="noopener noreferrer"
                target="_blank"
              >
                "AF1"
              </a>{' '}
              , released on August 28th. He quickly followed up with another
              single,{' '}
              <a
                href="https://ffm.to/oxygen-single"
                rel="noopener noreferrer"
                target="_blank"
              >
                "OXYGEN"
              </a>
              , on October 30th.
            </div>
          </div>
        </div>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage="cover.jpg"
          bgImageAlt="waves"
          strength={500}
        >
          <div className="section">
            <h1>VIDEOS</h1>
            <div className="row">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/t9Xbhag1sWs"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="OXYGEN"
              ></iframe>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/YdqR81xnGMU"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="restinprada"
              ></iframe>
            </div>
          </div>
        </Parallax>

        <div className="banner">
          <div>
            <h1>MUSIC</h1>
            <div style={{ width: '100%' }}>
              <Slider className="slider" {...settings}>
                <div>
                  <a
                    href="https://ffm.to/oxygen-single"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.oxygen2.childImageSharp.fixed}
                      alt="OXYGEN"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://ffm.to/kleffylewave"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.af1.childImageSharp.fixed}
                      alt="AF1"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://smarturl.it/restinprada"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.rip.childImageSharp.fixed}
                      alt="REST IN PRADA"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://smarturl.it/coldfeet"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.coldfeet.childImageSharp.fixed}
                      alt="COLD FEET"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://smarturl.it/telescope-single"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.telescope.childImageSharp.fixed}
                      alt="TELESCOPE"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://smarturl.it/7iuyzh"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.maxpayne.childImageSharp.fixed}
                      alt="Max Payne"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://smarturl.it/e57al6"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Img
                      fadeIn={false}
                      fixed={data.splashtized2.childImageSharp.fixed}
                      alt="SPLASTiZED 2"
                    />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage="cover.jpg"
          bgImageAlt="waves"
          strength={500}
        >
          <div className="photos">
            <h1>PHOTOS</h1>
            <Gallery />
          </div>
        </Parallax>

        <div className="banner">
          <div className="subscribe">
            <h1>SUBSCRIBE</h1>
            <form
              name="contact"
              method="POST"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <input type="email" name="email" placeholder="E-mail" />
              <button type="submit">SUBSCRIBE</button>
            </form>
          </div>
        </div>
      </div>
      <footer>KLEFFY LE WAVE © {new Date().getFullYear()}</footer>
    </>
  );
};

export default Home;
